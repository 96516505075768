import React from "react";
import { CustomEmbeds } from "../../components/BlockContent/BlockContent";
import Card from "../../components/Card/Card";
import CreditedContent from "../../components/CreditedContent/CreditedContent";
import { ContentFields } from "../../model/common";
import styles from "./FeaturesUserGuide.module.scss";

interface FeaturesUserGuideContentProps extends ContentFields {
  customEmbeds?: CustomEmbeds;
}

const FeaturesUserGuideContent = ({
  ...others
}: FeaturesUserGuideContentProps) => {
  return (
    <>
      <Card className={styles.card} type="main">
        <CreditedContent
          {...others}
          className={styles.blockContent}
          cap="3/4"
        />
      </Card>
    </>
  );
};

export default FeaturesUserGuideContent;
