import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import LeftNavLayout from "../layouts/LeftNavLayout/LeftNavLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign, SanityPage } from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import { SanityFeaturesPage } from "../model/get-started";

export const pageQuery = graphql`
  query FeaturesPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    config: sanityFeaturesConfig(language: { eq: $navLanguage }) {
      featuresMenu {
        _id
        _type
        title
        slug {
          current
        }
      }
    }
    page: sanityFeaturesPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 6 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "getStarted" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const FeaturesPage = ({
  pageContext,
  data: {
    menus,
    page,
    config: { featuresMenu: menu },
    campaigns
  },
  location
}: FeaturesPageProps) => {
  const { title, _rawContent, translationSponsors, licence } = page;
  return (
    <PageLayout
      siteArea={SiteArea.GET_STARTED}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <LeftNavLayout
        campaigns={campaigns.nodes}
        h1TextId="features"
        h2={title}
        menu={menu.map((d: SanityPage) => ({
          internalLink: d,
          title: d.title,
          _key: d._id
        }))}
        content={_rawContent}
        translationSponsors={translationSponsors}
        licence={licence}
      />
    </PageLayout>
  );
};

export default FeaturesPage;

interface FeaturesPageProps {
  data: {
    menus: GlobalMenus;
    config: any;
    page: SanityFeaturesPage;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
